<template>
	<div>
		<!-- Iframe para mostrar el PDF -->
		<iframe ref="pdfFrame" style="width: 100%; height: 100vh; border: none;"></iframe>

		<!-- Contenido del recibo envuelto en un div y oculto para captura -->
		<div class="receipt">
			<div class="sheet">
				<div class="invoice-wrapper" ref="receiptContent">
					<div class="invoice">
						<div class="invoice-container">
							<div class="invoice-head">
								<div class="invoice-head-top">
									<div class="invoice-head-top-left text-start">
										<img :src="`data:image/jpg;base64,${reporte.imagenBase64}`">
									</div>
									<div class="invoice-head-top-center text-start">
										<ul>
											<li class="text-bold text-blue text-14">{{ reporte.empresa }}</li>
											<li class="text-13">{{ reporte.direccion }}</li>
											<li class="text-13">{{ reporte.telefono }}</li>
											<li class="text-13">{{ reporte.correo }}</li>
											<li class="text-13">{{ reporte.ubicacion }}</li>
										</ul>
									</div>
									<div class="invoice-head-top-right text-end text-blue">
										<span class="text-bold text-30">{{ reporte.documento }}</span><br>
										<span class="text-bold text-blue text-14">{{ reporte.almacen }}</span><br>
										<span class="text-blue text-14">Usuario: {{ reporte.usuario }}</span><br>
										<span v-if="reporte.estado === 'VENTA ANULADA'" class="text-blue text-bold text-14" style="color:#C41A06;">{{ reporte.estado }}</span>
									</div>
								</div>
								<div class="hr"></div>
								<div class="invoice-head-middle text-14">
									<div class="invoice-head-middle-left text-start">
										<p><span class="text-bold text-blue">Fecha de Venta</span>: {{ reporte.fechaventa }} {{ reporte.horaventa }}</p>
									</div>
									<div class="invoice-head-middle-right text-end">
										<p><span class="text-bold text-blue">Nota No: </span>{{ reporte.nroventa }}</p>
									</div>
								</div>
								<div class="hr"></div>
								<div class="invoice-head-bottom text-14">
									<div class="invoice-head-bottom-left">
										<ul>
											<li><span class="text-bold text-blue">Nombre/Razón Social:</span> {{ reporte.clientenombre }}</li>
											<li><span class="text-bold text-blue">NIT/CI/CEX:</span> {{ reporte.nitci }}</li>
										</ul>
									</div>
									<div class="invoice-head-bottom-right">
										<ul class="text-end">
											<li><span class="text-bold text-blue">Tipo de Venta:</span> {{ reporte.tipodeventa }}</li>
											<li><span class="text-bold text-blue">Moneda:</span> {{ reporte.nombreMoneda }}</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="overflow-view">
								<div class="invoice-body">
									<table>
										<thead>
											<tr>
												<td class="text-bold">Nro</td>
												<td class="text-bold">Código</td>
												<td class="text-bold">Descripción</td>
												<td class="text-bold text-center">Cantidad</td>
												<td class="text-bold text-center">P.Unitario</td>
												<td class="text-bold text-center">Descuento</td>
												<td class="text-bold text-end">Subtotal</td>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in reporte.detalle" :key="index">
												<td>{{ index + 1 }}</td>
												<td>{{ item.codigo }}</td>
												<td>{{ item.nombre }} {{ item.medida }}</td>
												<td class="text-center">{{ item.cantidad }}</td>
												<td class="text-center">{{ item.precioVenta }}</td>
												<td class="text-center">{{ item.descuento }}</td>
												<td class="text-end">{{ item.subtotal }}</td>
											</tr>
										</tbody>
									</table>
									<div class="invoice-body-bottom text-13">
										<div class="invoice-body-info-item border-bottom">
											<div class="info-item-td text-end text-bold text-blue">Subtotal {{ reporte.simbolo }}:</div>
											<div class="info-item-td text-end">{{ reporte.montototal }}</div>
										</div>
										<div class="invoice-body-info-item border-bottom">
											<div class="info-item-td text-end text-bold text-blue">Descuento {{ reporte.simbolo }}:</div>
											<div class="info-item-td text-end">{{ reporte.descuento }}</div>
										</div>
										<div class="invoice-body-info-item border-bottom">
											<div class="info-item-td text-end text-bold text-blue">Total {{ reporte.simbolo }}:</div>
											<div class="info-item-td text-end">{{ reporte.montocancelado }}</div>
										</div>
										<div class="invoice-body-info-item border-bottom">
											<div class="info-item-td text-end text-bold text-blue">Entregado {{ reporte.simbolo }}:</div>
											<div class="info-item-td text-end">{{ reporte.efectivo }}</div>
										</div>
										<div class="invoice-body-info-item">
											<div class="info-item-td text-end text-bold text-blue">Cambio {{ reporte.simbolo }}:</div>
											<div class="info-item-td text-end">{{ reporte.cambio }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p><span class="text-bold text-center text-blue text-14">Son:&nbsp;</span><span style="color:#000000;" class="text-14">{{ reporte.montoliteral }}</span></p>
						<div class="invoice-foot text-center text-13">
							<p v-if="reporte.observacion"><span class="text-bold text-center text-blue ">Observación:&nbsp;</span>{{ reporte.observacion }}</p>
							<span class="text-center text-blue text-center">Reporte generado por www.efectivo.io <br> Fecha Reporte {{ reporte.fechareporte }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="spinnerloading == true" class="spinner-overlay">
			<div class="spinner-container">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as utils from '@/utils/utils'
import router from '@/config/router'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
  
export default {

	props: {
		ventaID: { type: String, required: true }
	},

	data () {
		return {
			spinnerloading: false
		}
	}, 

	computed: {
		...mapGetters('auth', ['user']),
		...mapGetters('venta', ['reporte'])
	},

	methods: {
		...mapActions('venta', ['getReporteVenta']),

		imprimir () {
			this.spinnerloading = true
			this.getReporteVenta(this.ventaID)
			.then(response => {
				this.generatePDF()
				.then(() => {
                    this.spinnerloading = false
                    if (this.isMobile()) {
                        this.downloadPDF()
                    } else {
                        this.showPDF()
                    }
                })
			}).catch(error => {
			   utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
			})
		},

		async generatePDF () {
			const receiptContent = this.$refs.receiptContent
			const pdfFrame = this.$refs.pdfFrame

			const canvas = await html2canvas(receiptContent, {
				scale: 3,
				useCORS: true,
				logging: false,
				allowTaint: true,
				letterRendering: true,
				backgroundColor: null,
				dpi: 80
			})

			const imgData = canvas.toDataURL('image/png')

			const pdf = new jsPDF({ // eslint-disable-line new-cap
				orientation: 'portrait',
				unit: 'mm',
				format: 'letter',
				compress: true // Optimización de compresión para reducir el tamaño del PDF
			})

			const imgProps = pdf.getImageProperties(imgData)
			const pdfWidth = pdf.internal.pageSize.getWidth()
			const pdfHeight = pdf.internal.pageSize.getHeight()
			const pdfHeightOther = (imgProps.height * pdfWidth) / imgProps.width // Una sola pagina
			const canvasWidth = canvas.width
			const canvasHeight = canvas.height
			const ratio = canvasWidth / pdfWidth
			const pageHeight = pdfHeight * ratio
			let pageNumber = 0
			const totalPages = Math.ceil(canvasHeight / pageHeight)

			if (totalPages > 1) {
				while (pageNumber * pageHeight < canvasHeight) {
					if (pageNumber > 0) {
						pdf.addPage()
					}

					const startY = pageNumber * pageHeight
					const canvasPage = document.createElement('canvas')
					const ctx = canvasPage.getContext('2d')

					canvasPage.width = canvasWidth
					canvasPage.height = pageHeight

					ctx.drawImage(canvas, 0, startY, canvasWidth, pageHeight, 0, 0, canvasWidth, pageHeight)

					const imgDataPage = canvasPage.toDataURL('image/png')
					pdf.addImage(imgDataPage, 'PNG', 0, 5, pdfWidth, pdfHeight - 25)

					// Añadir número de página
					pdf.setFontSize(10)
					pdf.text(`Página ${pageNumber + 1} de ${totalPages}`, pdfWidth / 2, pdfHeight - 10, { align: 'center' })

					pageNumber++
				}
			} else {
				pdf.addImage(imgData, 'PNG', 0, 5, pdfWidth, pdfHeightOther)
			}
			this.pdfDataUri = pdf.output('bloburi')
            this.pdfBlob = pdf.output('blob')
		},

		isMobile () {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        },

        showPDF () {
            const receiptContent = this.$refs.receiptContent
            const pdfFrame = this.$refs.pdfFrame
            receiptContent.style.display = 'none'
            pdfFrame.src = this.pdfDataUri
            setTimeout(() => {
                pdfFrame.contentWindow.print()
            }, 300)
        },
		
        downloadPDF () {
            const file = `venta-${this.ventaID}.pdf`
            const link = document.createElement('a')
            link.href = URL.createObjectURL(this.pdfBlob)
            link.download = file
            link.click()
            setTimeout(() => {
                window.close()
            }, 1000)
        }
	},

	mounted () {
		this.imprimir()
	}
}
</script>

<style scoped>
	/* Establecer tamaño de la página en carta */
	@page {
		size: 216mm 279mm; /* Tamaño carta en milímetros */
		margin: 0;
	}

	/* Establecer tamaño de la hoja (contenido) */
	body.receipt .sheet {
		width: 216mm;
		height: auto;
		margin: 0 auto;
		color: #000000;
	}

	/* Forzar ancho de la página en modo de impresión */
	@media print {
		body.receipt {
			width: 216mm;
			margin: 0;
		}
		.sheet {
			width: 100%;
			box-shadow: none;
			page-break-inside: avoid;
		}
	}

	/* Desactivar responsividad para mantener tamaño fijo */
	@media screen and (max-width: 1200px) {
		body.receipt, .sheet {
			width: 216mm;
			margin: 0 auto;
		}
	}

	.invoice-wrapper {
		width: 216mm;
		height: auto;
	}

	.invoice {
		width: 100%;
		height: 100%;
		padding: 0px 40px;
		margin: 0 auto;
		page-break-inside: avoid !important;
	}

	/* Estilos generales */
	*,
	*::after,
	*::before{
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	ul{
		list-style-type: none;
	}

	ul li{
		margin: 2px 0;
	}

	/* text colors */
	.text-dark{
		color: #000000;
	}
	.text-blue{
		color: #000000;
	}
	.text-end{
		text-align: right;
	}
	.text-center{
		text-align: center;
	}
	.text-start{
		text-align: left;
	}
	.text-bold{
		font-weight: 700;
	}
	.text-30 {
		font-size: 30px;
	}
	.text-14 {
		font-size: 14px;
	}
	.text-13 {
		font-size: 13px;
	}

	/* hr line */
	.hr{
		height: 1px;
		background-color: rgba(0, 0, 0, 0.1);
	}
	/* border-bottom */
	.border-bottom{
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.invoice-head-top-left img{
		width: 130px;
	}

	.invoice-head-top-center {
		margin-left: -100px;
	}

	.invoice-head-top-right h3{
		font-weight: 500;
		color: #000000;
	}

	.invoice-head-middle, .invoice-head-bottom{
		padding: 0px 0;
	}

	.invoice-body{
		border-radius: 4px;
		overflow: hidden;
	}

	.invoice-body table{
		border-collapse: collapse;
		border-radius: 4px;
		width: 100%;
		font-size: 12px;
		page-break-inside: avoid;
	}

	.invoice-body table td, .invoice-body table th{
		padding: 5px;
	}

	.invoice-body table tr{
		border: 1px solid rgba(0, 0, 0, 0.1);
		page-break-inside: avoid;
	}
	
	.invoice-body table thead{
		color: #fff;
		background-color: rgba(0, 0, 0, 0.842);
	}

	.invoice-body table tbody tr{
		color:#000000;
	}

	.invoice-body-bottom{
		width: 28%;
		margin-top: -1px;
		margin-left: auto;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.invoice-body-info-item{
		display: grid;
		grid-template-columns: 60% 40%;
	}

	.invoice-body-info-item .info-item-td{
		padding: 0px;
		color:#000000;
	}

	.invoice-foot{
		padding: 10px 0;
	}

	.invoice-head-top {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		padding-bottom: 5px;
	}

	.invoice-head-middle, .invoice-head-bottom{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding-bottom: 5px;
	}

	.overflow-view{
		overflow-x: none;
	}
	.invoice-body{
		min-width: 100%;
	}

	.spinner-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 1);
		z-index: 1055;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;
	}

	.spinner-container {
		text-align: center;
	}
</style>
